import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M2955 3720 c-8 -45 -25 -51 -75 -25 -37 19 -39 5 -4 -40 l25 -34 -40
-21 c-23 -11 -41 -25 -41 -30 0 -6 21 -10 46 -10 l46 0 -7 -52 -6 -53 37 40
36 40 35 -40 35 -40 -6 53 -7 52 51 0 c59 0 58 10 -1 41 l-40 20 30 41 c17 23
29 43 27 46 -2 2 -18 -4 -35 -12 -46 -24 -59 -20 -72 24 -7 22 -16 40 -20 40
-4 0 -10 -18 -14 -40z"/>
<path d="M2462 3684 c-15 -10 -22 -25 -22 -49 0 -59 54 -91 99 -58 74 55 -1
160 -77 107z"/>
<path d="M2274 3522 c-35 -17 -84 -67 -84 -86 0 -3 17 3 38 14 20 11 63 22 95
26 66 8 66 8 41 -76 -32 -107 -86 -211 -203 -389 -15 -24 -15 -24 9 -11 36 19
201 186 249 254 24 32 60 87 80 123 l37 65 89 2 c75 1 100 6 149 29 67 31 69
44 5 27 -51 -14 -174 -7 -259 15 -30 8 -89 17 -130 20 -62 5 -82 2 -116 -13z"/>
<path d="M3070 3373 c1 -4 7 -19 15 -33 8 -14 14 -45 15 -71 1 -181 -299 -399
-726 -527 -139 -41 -243 -62 -419 -81 l-150 -17 90 -17 c50 -9 105 -17 124
-17 28 0 46 -11 102 -65 57 -54 150 -115 174 -115 3 0 -5 15 -18 33 -27 35
-60 98 -71 135 -6 19 -4 22 21 22 25 0 32 -7 57 -56 15 -32 39 -72 53 -90 25
-34 81 -74 101 -74 8 0 12 43 14 128 l3 127 28 3 27 3 0 -130 c0 -85 4 -131
10 -131 23 0 68 31 100 67 36 40 93 156 105 212 6 29 5 33 -10 27 -9 -3 -35
-9 -58 -12 -40 -5 -39 -4 23 24 36 15 81 36 100 45 23 11 67 17 125 19 l90 3
10 60 c10 56 16 65 81 130 123 123 157 237 96 321 -27 36 -112 96 -112 77z"/>
<path d="M2497 3330 c-33 -39 -34 -55 -4 -57 62 -5 70 -12 64 -53 l-5 -40 39
47 c62 75 52 112 -32 128 -32 6 -39 3 -62 -25z"/>
<path d="M2750 3065 l0 -146 58 37 c31 20 65 42 75 48 14 9 17 26 17 108 l0
98 -75 0 -75 0 0 -145z"/>
<path d="M2527 3133 c-4 -3 -7 -74 -7 -156 l0 -149 28 5 c15 3 48 15 75 26
l47 20 0 131 0 130 -68 0 c-38 0 -72 -3 -75 -7z"/>
<path d="M2300 2874 c0 -115 2 -125 18 -120 9 2 28 7 42 11 14 3 37 11 53 17
l27 10 0 104 0 104 -70 0 -70 0 0 -126z"/>
<path d="M2070 2795 l0 -95 43 6 c96 16 92 11 95 102 l3 82 -71 0 -70 0 0 -95z"/>
<path d="M2776 2649 c-17 -63 -55 -144 -88 -191 -22 -32 -18 -31 60 10 73 39
212 191 212 232 0 6 -33 10 -83 10 l-84 0 -17 -61z"/>
<path d="M1620 2080 l0 -100 25 0 25 0 0 100 0 100 -25 0 -25 0 0 -100z"/>
<path d="M2070 2167 c0 -20 9 -25 31 -20 28 8 24 33 -6 33 -14 0 -25 -6 -25
-13z"/>
<path d="M2514 2166 c-3 -7 -4 -19 -2 -27 2 -8 -3 -13 -11 -11 -8 2 -25 1 -38
-3 -21 -5 -23 -11 -23 -63 0 -32 5 -63 12 -70 7 -7 34 -12 60 -12 l48 0 0 100
c0 94 -1 100 -20 100 -11 0 -23 -6 -26 -14z m-4 -111 c0 -19 -4 -35 -10 -35
-5 0 -10 16 -10 35 0 19 5 35 10 35 6 0 10 -16 10 -35z"/>
<path d="M1096 2162 c-2 -4 -4 -46 -3 -92 l2 -85 58 -3 c52 -3 57 -1 57 18 0
16 -6 20 -35 20 -28 0 -35 4 -35 20 0 16 7 20 30 20 23 0 30 4 30 20 0 16 -7
20 -30 20 -20 0 -30 5 -30 15 0 10 11 15 35 15 28 0 35 4 35 20 0 18 -7 20
-54 20 -30 0 -57 -3 -60 -8z"/>
<path d="M1990 2146 c0 -8 -5 -16 -12 -18 -8 -3 -9 -11 -3 -24 5 -10 10 -36
11 -57 3 -55 10 -67 39 -67 18 0 25 5 25 20 0 11 -4 20 -10 20 -5 0 -10 16
-10 35 0 19 5 35 10 35 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0
8 -9 15 -20 15 -11 0 -20 -6 -20 -14z"/>
<path d="M2740 2145 c0 -8 -4 -15 -10 -15 -6 0 -6 -9 0 -26 6 -14 10 -39 10
-55 0 -44 17 -69 45 -69 18 0 25 5 25 20 0 11 -4 20 -10 20 -5 0 -10 16 -10
35 0 19 5 35 10 35 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 7 -10 15 0 9
-9 15 -25 15 -16 0 -25 -6 -25 -15z"/>
<path d="M1227 2103 c30 -124 27 -118 61 -121 18 -2 32 0 32 5 0 4 9 39 19 76
l19 68 -27 -3 c-21 -2 -27 -9 -32 -41 -9 -54 -16 -54 -23 -2 -6 39 -10 45 -32
45 -22 0 -24 -3 -17 -27z"/>
<path d="M1383 2123 c-9 -3 -13 -27 -13 -68 0 -68 6 -75 60 -75 41 0 60 10 60
31 0 13 -7 19 -24 19 -13 0 -26 -5 -28 -12 -2 -6 -10 -8 -16 -4 -24 14 -11 26
28 26 40 0 40 0 40 39 0 26 -5 41 -16 45 -18 7 -73 7 -91 -1z m62 -33 c3 -6
-1 -13 -10 -16 -19 -8 -30 0 -20 15 8 14 22 14 30 1z"/>
<path d="M1520 2055 c0 -68 2 -75 20 -75 11 0 20 6 21 13 4 59 10 83 23 93 29
21 18 39 -24 41 l-40 3 0 -75z"/>
<path d="M1700 2110 c-14 -26 -12 -93 3 -113 9 -12 25 -16 62 -14 l50 2 -2 40
c0 22 -2 54 -2 71 -1 31 -2 32 -51 32 -39 0 -53 -5 -60 -18z m68 -62 c-5 -37
-28 -33 -28 5 0 18 3 37 7 41 10 10 25 -21 21 -46z"/>
<path d="M1852 2121 c-8 -5 -12 -22 -10 -42 3 -30 7 -34 36 -37 31 -3 41 -15
23 -27 -5 -3 -12 -1 -16 5 -11 18 -45 11 -45 -9 0 -21 19 -31 55 -31 43 0 55
10 55 46 0 30 -4 35 -30 41 -33 6 -46 18 -31 27 5 4 12 2 16 -4 3 -5 15 -10
26 -10 23 0 26 35 3 44 -22 9 -67 7 -82 -3z"/>
<path d="M2070 2055 c0 -73 1 -75 25 -75 24 0 25 2 25 75 0 73 -1 75 -25 75
-24 0 -25 -2 -25 -75z"/>
<path d="M2150 2055 c0 -73 1 -75 25 -75 24 0 25 3 25 55 0 30 5 55 10 55 6 0
10 -25 10 -55 0 -52 1 -55 25 -55 24 0 25 2 25 69 0 78 -1 78 -77 80 l-43 1 0
-75z"/>
<path d="M2300 2110 c-12 -23 -13 -80 0 -100 5 -8 19 -15 32 -15 36 -2 37 -20
1 -23 -21 -2 -33 -9 -33 -18 0 -10 13 -14 50 -14 61 0 66 8 65 112 l0 73 -53
2 c-42 1 -54 -2 -62 -17z m70 -50 c0 -20 -5 -30 -15 -30 -10 0 -15 10 -15 30
0 20 5 30 15 30 10 0 15 -10 15 -30z"/>
<path d="M2603 2123 c-9 -3 -13 -26 -13 -68 0 -71 2 -72 78 -74 l42 -1 0 74 0
75 -47 -1 c-27 0 -54 -3 -60 -5z m57 -68 c0 -19 -4 -35 -10 -35 -5 0 -10 16
-10 35 0 19 5 35 10 35 6 0 10 -16 10 -35z"/>
<path d="M2843 2123 c-9 -3 -13 -26 -13 -68 0 -71 2 -72 78 -74 l42 -1 0 74 0
75 -47 -1 c-27 0 -54 -3 -60 -5z m57 -68 c0 -19 -4 -35 -10 -35 -5 0 -10 16
-10 35 0 19 5 35 10 35 6 0 10 -16 10 -35z"/>
<path d="M3063 2123 c-17 -6 -17 -115 -1 -131 7 -7 28 -12 48 -12 40 0 60 14
60 42 0 19 -34 25 -45 8 -13 -21 -25 -9 -25 25 0 34 12 46 25 25 3 -5 15 -10
26 -10 24 0 27 45 3 54 -18 7 -73 7 -91 -1z"/>
<path d="M3208 2119 c-21 -11 -25 -87 -8 -120 12 -21 72 -27 99 -10 15 10 21
92 9 124 -8 19 -71 24 -100 6z m60 -70 c-2 -19 -7 -33 -13 -32 -12 3 -19 49
-10 71 10 26 27 -2 23 -39z"/>
<path d="M3340 2054 c0 -72 1 -74 25 -74 24 0 25 3 25 56 0 40 4 54 12 52 9
-3 12 -23 11 -56 -1 -49 1 -52 23 -52 22 0 24 4 24 55 0 42 3 55 15 55 12 0
15 -13 15 -55 0 -52 1 -55 25 -55 24 0 25 3 25 58 0 84 -4 87 -110 88 l-90 2
0 -74z"/>
<path d="M3640 2055 c0 -73 1 -75 25 -75 24 0 25 3 25 55 0 30 5 55 10 55 6 0
10 -25 10 -55 0 -52 1 -55 25 -55 24 0 25 2 25 69 0 78 -1 78 -77 80 l-43 1 0
-75z"/>
<path d="M3803 2123 c-9 -3 -13 -25 -13 -63 0 -62 15 -79 55 -64 13 5 16 3 13
-7 -2 -8 -19 -15 -36 -17 -20 -2 -32 -9 -32 -18 0 -18 90 -20 108 -2 7 7 12
44 12 94 l0 83 -47 -1 c-27 -1 -54 -3 -60 -5z m57 -63 c0 -30 -13 -40 -25 -20
-8 13 4 50 16 50 5 0 9 -13 9 -30z"/>
<path d="M2980 2006 c0 -21 5 -26 26 -26 21 0 25 4 22 23 -2 14 -11 23 -25 25
-19 3 -23 -1 -23 -22z"/>
<path d="M3560 2005 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
